<template>
  <v-app class="supervisor-home">
    <v-navigation-drawer v-model="drawer" app color="navbar" dark>
      <v-img :src="logo" class="mx-4 mt-10 mb-4" max-height="200" contain />
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="navbarText">
            <v-icon color="navbar">person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="navbarText--text">{{
              user.displayName
            }}</v-list-item-title>
            <v-list-item-subtitle class="navbarText--text">{{
              user.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-subheader class="navbarText--text">{{
        businessGroup.name.toUpperCase("tr-TR")
      }}</v-subheader>

      <v-list class="py-0 my-0">
        <v-list-item>
          <v-list-item-content class="py-0 my-0">
            <v-select
              v-model="selectedBusinessId"
              :items="businessGroup.businesses"
              item-text="name"
              item-value="id"
              class="py-0 my-0"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          @click.prevent="goTo(item)"
          :to="item.link"
          :exact="item.exact"
        >
          <v-list-item-action>
            <v-icon color="navbarText">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="navbarText--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item link @click.prevent="signOut()">
          <v-list-item-action>
            <v-icon color="navbarText">exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="navbarText--text"
              >Çıkış</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevate-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ title }}</span>
      </v-toolbar-title>
    </v-app-bar>

    <v-main class="content">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  data: () => ({
    drawer: null,
    title: "",
    currentMenuItem: {},
    menuItems: [
      {
        icon: "dashboard",
        title: "Giriş",
        link: { name: "supervisor-home" },
        exact: true
      },
      {
        icon: "people",
        title: "Kullanıcılar",
        link: { name: "supervisor-users" },
        exact: true
      },
      {
        icon: "mdi-format-list-text",
        title: "Eğitimler",
        link: { name: "supervisor-courses" },
        exact: false
      },
      {
        icon: "settings",
        title: "Ayarlar",
        link: { name: "supervisor-settings" },
        exact: true
      }
    ],
    logo: require("../../assets/img/utex-b2b-logo.png"),
    selectedBusinessId: "..."
  }),
  computed: {
    userSignedIn() {
      return this.$store.getters["auth/userSignedIn"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    businessGroup() {
      return this.$store.getters["auth/businessGroup"];
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut");
    },
    goTo(item) {
      this.currentMenuItem = item;
      this.title = item.title;
      this.$router.push(item.link).catch(() => {
        return false;
      });
    }
  },
  watch: {
    selectedBusinessId: async function(val) {
      this.$store.dispatch(
        "auth/changeBusiness",
        this.businessGroup.businesses.find(item => item.id === val)
      );
    }
  },
  async mounted() {
    this.currentMenuItem = this.menuItems[0];
    this.title = this.currentMenuItem.title;

    window.Tawk_API.hideWidget();

    this.logo = this.businessGroup.logo;
    this.$store.dispatch("auth/changeTheme", "businessGroup");

    this.selectedBusinessId = this.businessGroup.businesses[0].id;
  }
};
</script>

<style lang="scss" scoped>
.supervisor-home {
  .content {
    background-image: url("../../assets/img/bg-top.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }

  .course {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all ease 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    .v-card__title {
      min-height: 50px;
      align-items: flex-start;
    }

    .v-card__subtitle {
      span {
        display: inline-block;
        width: 25px;
        height: 5px;
        border-radius: 2px;
        margin-left: 10px;
      }
    }
  }
}
</style>
